import { EntityUnionViewModel } from './shared/EntityUnionViewModel';
import { FetchResult } from '@apollo/client';
import { EntityUnionEnum, SearchDuplicateEmailsQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

export type Staff = Extract<
  SearchDuplicateEmailsQuery['searchDuplicateEmails'],
  { staffID: number }
>;
export type Person = Extract<
  SearchDuplicateEmailsQuery['searchDuplicateEmails'],
  { personID: number }
>;
export type PersonEmailAddress = ArrayElement<Person['EmailAddresses']>;
export type Phone = ArrayElement<Person['Phones']>;
export type Child = ArrayElement<Person['Children']>;
export type Region = NonNullable<Person['Teen']>['Region'];
export type AdvisorRegion = ArrayElement<Staff['AdvisorRegions']>;

export enum ErrorEnum {
  DuplicateFound = 'Error Duplicate Found',
  EmailValidationFaild = 'The email is invalid',
  SearchingDuplicates = 'Please wait while searching for duplicates...',
  RequiredField = 'Email is a required field'
}
export const RegionsMap: any = {
  1: 'Greater Boston',
  2: 'Atlantic Seaboard',
  3: 'Central East',
  4: 'West Coast',
  7: 'Tri State',
  8: 'Canada',
  9: 'Southwest',
  10: 'Southern',
  11: 'Midwest',
  13: 'Upstate New York',
  14: 'New Jersey',
  28: 'Atlanta',
  29: 'Argentina',
  30: 'Israel',
  31: 'International',
  32: 'Chile',
  33: 'National',
  35: 'Alumni',
  36: 'Mexico'
};

export type EmailAddress = PersonEmailAddress[] | string | null;
export interface Props {
  owner: EntityUnionViewModel;
  ownerId: number;
  value: string;
  searching: boolean;
  name: string;
  ownerType: EntityUnionEnum;
  duplicate: EntityUnionViewModel;
  searchDuplicates: (email: string) => Promise<FetchResult<SearchDuplicateEmailsQuery>>;
  required: boolean;
  left: boolean;
  narrow: boolean;
  width: string;
  placeholder: string;
  showErrors: boolean;
  validateOnBlur: boolean;
}
