export const gateways = {
    NCSY: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxOUSpec',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Alumni: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxOUSpec',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Argentina: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknox',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Arizona: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Atlanta: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknox',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_AtlanticSeaboard: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyATS',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_BritishColumbia: {
      defaultReceipt: 'CA',
      USReceipt: 'SensePassCardknox',
      CAReceipt: 'Chargent',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Canada: {
      defaultReceipt: 'CA',
      USReceipt: 'SensePassCardknox',
      CAReceipt: 'SensepassMonerisCanada',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Ottawa: {
      defaultReceipt: 'CA',
      USReceipt: 'SensePassCardknox',
      CAReceipt: 'Chargent',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Montreal: {
      defaultReceipt: 'CA',
      USReceipt: 'SensePassCardknox',
      CAReceipt: 'Chargent',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Vancouver: {
      defaultReceipt: 'CA',
      USReceipt: 'SensePassCardknox',
      CAReceipt: 'Chargent',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_CentralEast: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyCE',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Chile: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknox',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Cleveland: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyCE',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_LAValley: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_LACity: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_LasVegas: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Norcal: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_OrangeCounty: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Seattle: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_WestCoast: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_ParkCityUtah: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_SanDiego: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyWC',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Israel: {
      defaultReceipt: 'IL',
      USReceipt: 'SensePassCardknox',
      ILReceipt: 'SensePassCardcomPrograms'
   },
    NCSY_Midwest: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyMW',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_NewEngland: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxOUSpec',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_NewJersey: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyNJ',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_NewYork: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyNY',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Mexico: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknox',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Portland: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsyOR',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_ReliefMissions: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknox',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Southern: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsySth',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_Southwest: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNcsySW',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_STL: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxNCSYSTL'
    },
    NCSY_Summer: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknox',
      ILReceipt: 'SensePassCardcomDonations'
    },
    NCSY_UpstateNewYork: {
      defaultReceipt: 'US',
      USReceipt: 'SensePassCardknoxOUSpec',
      ILReceipt: 'SensePassCardcomDonations'
    }
  }

  export const gatewaysByRegion = {
    // Greater Boston
    1: gateways.NCSY_NewEngland,
    // Atlantic Seaboard
    2: gateways.NCSY_AtlanticSeaboard,
    // Central East
    3: gateways.NCSY_CentralEast,
    // West Coast
    4: gateways.NCSY_WestCoast,
    // New York
    7: gateways.NCSY_NewYork,
    // Canada
    8: gateways.NCSY_Canada,
    // Southwest
    9: gateways.NCSY_Southwest,
    // Southern
    10: gateways.NCSY_Southern,
    // Midwest
    11: gateways.NCSY_Midwest,
    // Upstate New York
    13: gateways.NCSY_UpstateNewYork,
    // New Jersey
    14: gateways.NCSY_NewJersey,
    // Atlanta
    28: gateways.NCSY_Atlanta,
    // Argentina
    29: gateways.NCSY_Argentina,
    // Israel
    30: gateways.NCSY_Israel,
    // International
    31: gateways.NCSY,
    // Chile
    32: gateways.NCSY_Chile,
    // National
    33: gateways.NCSY,
    // Alumni
    35: gateways.NCSY_Alumni
  }

  export const gatewaysByChapter = {
    // Oregon
    169: gateways.NCSY_Portland,
    // St Louis
    201: gateways.NCSY_STL
  }

  export type GatewayRegionId = keyof typeof gatewaysByRegion
  export type GatewayChapterId = keyof typeof gatewaysByChapter
