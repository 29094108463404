import SimplePayments from './SimplePayments.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  Event,
  ProcessRefundInput,
  RefundSimplePaymentMutation,
  useRefundSimplePaymentMutation,
  FullEventFragment,
  FullEventFragmentDoc
} from 'shared/generated/graphql-types';
import { MutateResult } from '@vue/apollo-composable';

interface Props {
  event: Event;
}

interface RefundSimplePaymentProps {
  refundSimplePayment: (input: ProcessRefundInput) => MutateResult<RefundSimplePaymentMutation>;
  refundingPayment: boolean;
};

const refundSimplePaymentEnhancer = wrapComponent<Props, RefundSimplePaymentProps>((props) => {
  const { loading, mutate } = useRefundSimplePaymentMutation();

  return computed(() => ({
    refundingPayment: loading.value,
    refundSimplePayment: (input: ProcessRefundInput) =>
      mutate(
        { input },
        {
          async update(client, { data }) {
            if (!data || !data?.refundSimplePayment) {
              alert('An unexpected error occurred while processing the refund.');
              return;
            }

            const { success, message, refund } = data.refundSimplePayment;
            alert(message || 'An unexpected error occurred.');

            if (success && refund) {
              var eventData = client.readFragment<FullEventFragment>({
                fragment: FullEventFragmentDoc,
                id: `${props.event.eventId}Event`,
                fragmentName: 'FullEvent'
              });;

              if (eventData) {
                const simplePayments = eventData.SimpleEventPayments.map((payment) => ({
                  ...payment,
                  Refunds: [...payment.Refunds],
                }));

                const simplePaymentId = simplePayments.findIndex((s) => s.id === refund.simplePaymentId);

                simplePayments[simplePaymentId] = {
                  ...simplePayments[simplePaymentId],
                  Refunds: [...simplePayments[simplePaymentId].Refunds, refund],
                };

                client.writeFragment<FullEventFragment>({
                  fragment: FullEventFragmentDoc,
                  id: `${props.event.eventId}Event`,
                  fragmentName: 'FullEvent',
                  data: {
                    ...eventData,
                    SimpleEventPayments: simplePayments,
                  },
                });
              }
            }
          }
        }
      )
  }));
});

export default compose(refundSimplePaymentEnhancer)(SimplePayments);
